/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { AshCharacterSkills } from '../data/ash-skills-data';
import { AshNexus } from './ash-nexus';

interface IProps {
  id?: string;
  maxLevel?: number;
  character_element?: string;
  type?: string;
  disabled?: boolean;
}

export const AshSkill: React.FC<IProps> = ({
  id,
  maxLevel,
  character_element,
  type,
  disabled
}) => {
  const [originalskillDesc, setOriginalskillDesc] = useState(
    AshCharacterSkills[id].description ? AshCharacterSkills[id].description : ''
  );
  const [originalParams, setOriginalParams] = useState(
    AshCharacterSkills[id].params
  );
  const [skillDesc, setSkillDesc] = useState(originalskillDesc);
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [color, setColor] = useState('#979797');

  // useEffect(() => {
  //   if (maxLevel === 7) {
  //     setCurrentLevel([6]);
  //   } else if (maxLevel === 12) {
  //     setCurrentLevel([10]);
  //   } else if (maxLevel === 8) {
  //     setCurrentLevel([8]);
  //   }
  // }, []);

  useEffect(() => {
    if (character_element === 'Wind') {
      setColor('#47a89e');
    } else if (character_element === 'Lightning') {
      setColor('#c3993d');
    } else if (character_element === 'Fire') {
      setColor('#bf4039');
    } else if (character_element === 'Ice') {
      setColor('#559bb2');
    } else if (character_element === 'Physical') {
      setColor('#8C969C');
    } else if (character_element === 'Water') {
      setColor('#5785e2');
    } else if (character_element === 'Corrosion') {
      setColor('#8d50e6');
    }
  }, []);

  useEffect(() => {
    const param1 = originalParams[currentLevel[0] - 1][0];
    const param2 = originalParams[currentLevel[0] - 1][1];
    const param3 = originalParams[currentLevel[0] - 1][2];
    const param4 = originalParams[currentLevel[0] - 1][3];
    const param5 = originalParams[currentLevel[0] - 1][4];
    const param6 = originalParams[currentLevel[0] - 1][5];
    const currentstring1 = '{0}';
    const currentstring2 = '{1}';
    const currentstring3 = '{2}';
    const currentstring4 = '{3}';
    const currentstring5 = '{4}';
    const currentstring6 = '{5}';

    const newString = originalskillDesc
      .replaceAll(`${currentstring1}`, `<b>${param1}</b>`)
      .replaceAll(`${currentstring2}`, `<b>${param2}</b>`)
      .replaceAll(`${currentstring3}`, `<b>${param3}</b>`)
      .replaceAll(`${currentstring4}`, `<b>${param4}</b>`)
      .replaceAll(`${currentstring5}`, `<b>${param5}</b>`)
      .replaceAll(`${currentstring6}`, `<b>${param6}</b>`)
      .replaceAll(`Wind DMG`, `<u class="wind">Wind DMG</u>`)
      .replaceAll(`Lightning DMG`, `<u class="lightning">Lightning DMG</u>`)
      .replaceAll(`Physical DMG`, `<u class="physical">Physical DMG</u>`)
      .replaceAll(`Ice DMG`, `<u class="ice">Ice DMG</u>`)
      .replaceAll(`Water DMG`, `<u class="water">Water DMG</u>`)
      .replaceAll(`Fire DMG`, `<u class="fire">Fire DMG</u>`)
      .replaceAll(`Corrosion DMG`, `<u class="corrosion">Corrosion DMG</u>`)
      .replaceAll(`\n`, `<br />`);
    setSkillDesc(newString);
  }, [currentLevel]);
  return (
    <>
      <div className="skill-header">
        <div className={`skill-icon ${character_element}`}>
          {type === 'Active' ? (
            <>{AshCharacterSkills[id].type} Skill</>
          ) : (
            <>{type === 'Combat' ? <>Combat Traits</> : <>{type} Skill</>}</>
          )}
        </div>
        <div className="skill-info">
          <p className="skill-name">
            {AshCharacterSkills[id].name
              ? AshCharacterSkills[id].name
              : 'Combat Traits'}
          </p>
          <p className="skill-type">
            {AshCharacterSkills[id].tags && AshCharacterSkills[id].tags}
          </p>
        </div>
      </div>
      {maxLevel === 1 ? (
        <></>
      ) : (
        <div className="level-changer">
          <span className="current-level">
            Lv.{' '}
            <span className="level" style={{ color: color }}>
              {currentLevel}
            </span>
          </span>
          <div className={`level-slider ${disabled ? 'disabled' : 'enabled'}`}>
            <Range
              disabled={disabled}
              step={1}
              min={1}
              max={maxLevel}
              values={currentLevel}
              onChange={(values) => setCurrentLevel(values)}
              renderTrack={({ props, children }) => (
                <div
                  role="button"
                  tabIndex={0}
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '5px',
                      width: '100%',
                      borderRadius: '0px',
                      background: getTrackBackground({
                        values: currentLevel,
                        colors: [color, '#484950'],
                        min: 1,
                        max: maxLevel
                      }),
                      alignSelf: 'center'
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: '0px',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      height: '14px',
                      width: '5px',
                      backgroundColor: isDragged ? color : '#484950'
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      )}
      <div className={`additional-information`}>
        {type === 'Active' && (
          <>
            {(AshCharacterSkills[id].type === 'Active' ||
              AshCharacterSkills[id].type === 'Selection') && (
              <>
                <p>
                  Command CD:{' '}
                  <span>{AshCharacterSkills[id].command_cooldown}s</span>
                </p>
                <p>
                  Uses: <span>{AshCharacterSkills[id].uses}</span>
                </p>
              </>
            )}
            {AshCharacterSkills[id].type === 'Auto' && (
              <>
                <p>
                  Casting CD:{' '}
                  <span>
                    {AshCharacterSkills[id].casting_cooldown != '-' ? (
                      <>{AshCharacterSkills[id].casting_cooldown}s</>
                    ) : (
                      <>-</>
                    )}
                  </span>
                </p>
                {AshCharacterSkills[id].consumption != '-' && (
                  <p>
                    Resource: <span>{AshCharacterSkills[id].consumption}</span>
                  </p>
                )}
              </>
            )}
            {AshCharacterSkills[id].type === 'Auto - Buff' && (
              <>
                <p>
                  Buff CD: <span>{AshCharacterSkills[id].buff_cooldown}s</span>
                </p>
                <p>
                  Buff Duration:{' '}
                  <span>{AshCharacterSkills[id].buff_duration}s</span>
                </p>
              </>
            )}
          </>
        )}
        {type === 'Basic' && (
          <>
            <p>
              Range: <span>{AshCharacterSkills[id].range}</span>
            </p>
            <p>
              ATK Speed: <span>{AshCharacterSkills[id].atk_speed}</span>
            </p>
          </>
        )}
        {type === 'Seed' && (
          <>
            <p>
              Charge Time: <span>{AshCharacterSkills[id].charge_time}s</span>
            </p>
          </>
        )}
      </div>
      {type === 'Combat' ? (
        <>
          <p className="with-space top-margin">
            <strong>Base Effect:</strong>
          </p>
          <div
            className={`skill-with-coloring ${character_element}`}
            dangerouslySetInnerHTML={{
              __html: AshCharacterSkills[id].base
            }}
          />
          <p className="with-space">
            <strong>Unlocks at Seed Lv. I:</strong>
          </p>
          <div
            className={`skill-with-coloring ${character_element}`}
            dangerouslySetInnerHTML={{
              __html: AshCharacterSkills[id].seed_1
            }}
          />
          <p className="with-space">
            <strong>Unlocks at Seed Lv. II:</strong>
          </p>
          <div className={`skill-with-coloring ${character_element}`}>-</div>
          <p className="with-space">
            <strong>Unlocks at Seed Lv. III:</strong>
          </p>
          <div
            className={`skill-with-coloring ${character_element}`}
            dangerouslySetInnerHTML={{
              __html: AshCharacterSkills[id].seed_3
            }}
          />
          <p className="with-space">
            <strong>Unlocks at Seed Lv. IV:</strong>
          </p>
          <div className={`skill-with-coloring ${character_element}`}>
            Skill level of [Skill 1] <strong>+3</strong>
          </div>
          <p className="with-space">
            <strong>Unlocks at Seed Lv. V:</strong>
          </p>
          <div className={`skill-with-coloring ${character_element}`}>
            Skill level of [Skill 2] <strong>+3</strong>
          </div>
        </>
      ) : (
        <>
          {type === 'Passive' ? (
            <>
              <div className={`skill-with-coloring ${character_element}`}>
                <AshNexus
                  name={AshCharacterSkills[id].name}
                  mode="data"
                  onProfile
                />
              </div>
            </>
          ) : (
            <>
              <div
                className={`skill-with-coloring ${character_element}`}
                dangerouslySetInnerHTML={{
                  __html: skillDesc
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
